@layer components {
  html,
  body {
    overflow-x: hidden;
    @apply font-urbanist bg-main text-white h-full;
  }
  #__next {
    @apply h-full;
  }
}

@layer components {
  .bg-main {
    /* @apply bg-blackPearl; */
    @apply dark:bg-gray-900;
  }
  .bg-capacity {
    --tw-gradient-from: #0c0d11;
    --tw-gradient-to: #282c38;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
    @apply bg-gradient-to-r;
  }
  .bg-capacity-used {
    --tw-gradient-from: #ff7a41;
    --tw-gradient-to: #ff4d01;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
    @apply bg-gradient-to-b;
  }
  .bg-component {
    @apply bg-darkGrayishblue;
    &--inactive {
      @apply bg-charade;
    }
  }
  .bg-tag {
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)), #484b56;
  }
  .border-base {
    @apply border-trout;
  }
  .border-focus {
    @apply border-athensGray;
  }

  .product-list-item {
    .focused-item {
      display: none;
      position: absolute;
      right: 0;
      top: 5px;
    }
    &:hover {
      background: linear-gradient(
        90deg,
        rgba(30, 33, 42, 0) 0%,
        rgba(235, 255, 0, 0.15) 74.73%,
        rgba(250, 82, 160, 0.2) 100%
      );
      .focused-item {
        display: block;
      }
    }
  }

  button.animate-highlight {
    background: linear-gradient(-45deg, #fb5b0d, #ff8c3d, #fb5b0d, #d94d0b);
    background-size: 300% 300%;
    animation: highlight-pulse 2.8s ease infinite;
    transition: background 0.3s ease, background-size 0.3s ease;
  }
  @keyframes highlight-pulse {
    0% {
      background-position: 0% 50%;
    }
    50% {
      background-position: 100% 50%;
    }
    100% {
      background-position: 0% 50%;
    }
  }
}
