@tailwind base;
@tailwind components;
@tailwind utilities;

* {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-smooth: never;
}

@layer components {
  .type-xs-normal {
    @apply text-xs font-normal leading-3;
  }
  .type-xs-caption {
    @apply text-xs font-semibold leading-3;
  }
  .type-xs-medium {
    @apply text-xs font-medium leading-3;
  }
  .type-sm-caption,
  .type-sm-semibold {
    @apply text-sm font-semibold leading-5;
  }
  .type-sm-normal {
    /* type-sm-regular */
    @apply text-sm font-normal leading-5;
  }
  .type-sm-medium {
    @apply text-sm font-medium leading-5;
  }
  .type-sm-bold {
    @apply text-sm font-bold leading-5;
  }
  .type-base-medium {
    @apply text-md font-medium leading-6;
  }
  .type-base-caption {
    @apply text-md font-semibold leading-6;
  }
  .type-base-semibold {
    @apply text-md font-semibold leading-6;
  }
  .type-base-bold {
    @apply text-md font-bold leading-4;
  }
  .type-lg-normal {
    @apply text-lg font-normal leading-6;
  }
  .type-lg-medium {
    @apply text-lg font-medium leading-7;
  }
  .type-lg-semibold {
    @apply text-lg font-semibold leading-7;
  }
  .type-xl-normal {
    @apply text-xl font-normal;
  }
  .type-xl-medium {
    @apply text-xl font-medium;
  }
  .type-xl-semibold {
    @apply text-xl font-semibold;
  }
  .type-2xl-medium {
    @apply text-2xl font-medium leading-8;
  }
  .type-2xl-semibold {
    @apply text-2xl font-semibold leading-8;
  }
  .type-2xl-bold {
    @apply text-2xl font-bold leading-8;
  }
  .type-3xl-semibold {
    @apply text-3xl font-semibold;
  }
  .type-3xl-bold {
    @apply text-3xl font-bold;
  }
  .type-4xl-semibold {
    @apply text-4xl font-semibold;
  }
  .type-4xl-bold {
    @apply text-4xl font-bold;
  }
}

@layer utilities {
  .hidden-scrollbar {
    -ms-overflow-style: none; /* IE, Edge 対応 */
    scrollbar-width: none; /* Firefox 対応 */
  }
  .hidden-scrollbar::-webkit-scrollbar {
    /* Chrome, Safari 対応 */
    display: none;
  }
  .list-item {
    position: relative;
    &:before {
      content: '';
      background-color: #fb5b0d;
      display: block;
      width: 8px;
      height: 8px;
      border-radius: 50%;
      position: absolute;
      top: 8px;
      left: -16px;
    }
  }
  input[type='text']:focus {
    @apply outline-0 ring-0 ring-transparent outline-none shadow-none;
  }
}

.container {
  @apply md:max-w-screen-lg lg:max-w-screen-xl xl:max-w-screen-2xl px-5;
}

main.container-fluid {
  @apply flex-grow xl:px-60 lg:px-44 md:px-28;

  .content {
    @apply mx-auto max-w-screen-xl p-4;
  }

  .content-lg {
    @apply mx-auto max-w-screen-lg p-4;
  }

  .content-md {
    @apply mx-auto max-w-screen-md p-4;
  }

  .content-sm {
    @apply mx-auto max-w-screen-sm p-4;
  }
}

ol.list-number {
  counter-reset: item;

  li::before {
    @apply h-[32px] w-[35px] box-content rounded-full bg-emerald-400 type-base-semibold text-gray-950 flex items-center justify-center;
    counter-increment: item;
    content: counter(item);
    transform: scale(1);
  }
}

.bg-uniswap {
  background: radial-gradient(
      49.23% 92.13% at -0.69% 100%,
      #ed22c0 0%,
      rgba(237, 34, 192, 0.3) 0.01%,
      rgba(237, 34, 192, 0) 100%
    )
    rgba(35, 6, 29, 0.7);
}

.deprecated {
  /* .bg-camelot {
    background: linear-gradient(
      90deg,
      rgba(255, 175, 29, 0.15) 15.63%,
      rgba(255, 175, 29, 0.04) 87.85%
    );
  } */
}

.deprecated {
  /* .bg-tag-camelot {
    @apply bg-thinYellow;
  } */
}

.bg-tag-camelot {
  background: rgba(255, 255, 255, 0.08);
}

.tippy-tooltip-content {
  text-align: start;
}

.tippy-tooltip {
  padding: 0;
  border-radius: 12px;
}
.tippy-popper[x-placement^='bottom'] [x-arrow] {
  border-bottom: 7px solid #11141c;
}
